<template>
  <div class="inline-block" :class="{ block: block }">
    <button class="btn" :class="{ block: block }" :type="type" :disabled="disabled" @Click="onClick">
      <slot></slot>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AppBtn',
  props: {
    block: {
      type: Boolean,
      default: false,
    }, // 是否是块状元素
    disabled: {
      type: Boolean,
      default: false,
    }, // 是否禁用
    type: {
      type: String,
      default: 'button'
    }, // 按钮类型 active为选中状态  star为收藏状态
    onClick: {
      type: Function
    }
  },
})
</script>

<style lang="scss" scoped>
.inline-block{
  display: inline-block;
}
.btn {
  display: inline-block;
  padding: 7px 23px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  color: #7b7b7b;
  font-size: 16px;
  cursor: pointer;
  font-family: Microsoft YaHei;
  transition: all .15s;
  &:hover{
    border-color: #67aee0;
  }
  &:disabled{
    color: #c8c8c8;
    border-color: #d3d3d3;
    cursor: not-allowed;
  }
  &[type='active']{
    background-color: #e0f3ff;
    border-color: #67aee0;
    color: #5b8fe8;
  }
  &[type='star']{
    background-color: #ff8400;
    border-color: #ff8400;
    color: #fff2dd;
  }
  &[type="down"]{
    background-color: #5E8DE8;
    border-color:#5E8DE8;
    color: #fff;
  }
  &[type="no-down"]{
    background-color: #BDBFCC;
    border-color:#BDBFCC;
    color: #fff;
  }
}
.block {
  display: block;
}
</style>