import { createStore } from 'vuex'
import { fetchloginApi } from '@/api/user/index.js'
import {
  getToken,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken
} from '@/api/cookie.js'
import router from '@/router/index'

const NAVLIST = sessionStorage.getItem('Teacher-navList')
const USER_INFO = localStorage.getItem('user')
const NOW_CHECK_ID = localStorage.getItem('nowCheckId')
const NOW_CHECK_SCIENCE_ID = localStorage.getItem('nowCheckSCienceId')
const TREE_ID = localStorage.getItem('__treeId')
export default createStore({
  state: {
    isLoading: false,
    loadingTips: '',
    token: getToken(),
    routerName: '',
    userInfo: USER_INFO ? JSON.parse(USER_INFO) : {},
    navList: NAVLIST ? JSON.parse(NAVLIST) : '', // 导航栏
    nowCheckId: NOW_CHECK_ID ? Number(NOW_CHECK_ID) : Number(TREE_ID), // 默认选中的课程id
    nowCheckSCienceId: NOW_CHECK_SCIENCE_ID
      ? Number(NOW_CHECK_SCIENCE_ID)
      : Number(TREE_ID), // 默认选中的科学课程id
    isUploadStatus: false, // 上传列表默认隐藏
    storeUploadList: [], // 上传列表
    currentPage: 1
  },
  getters: {
    nowCheckIdGet: state => state.nowCheckId,
    nowCheckSCienceIdGet: state => state.nowCheckSCienceId,
    upLoadLen: state =>
      state.storeUploadList.filter(item => item.upStatus === 2).length
  },
  mutations: {
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setToken(state, res) {
      state.token = res.token
      setToken(res.token)
      setRefreshToken(res.refreshToken)
    },
    setLoding(state, value) {
      state.isLoading = value
    },
    saveRouterName(state, value) {
      state.routerName = value
      // localStorage.setItem('routerName', value)
    },
    setUserInfo(state, value) {
      state.userInfo = value
      localStorage.setItem('user', JSON.stringify(value))
    },
    setNavList(state, value) {
      sessionStorage.setItem('Teacher-navList', JSON.stringify(value))
      state.navList = value
    },
    setNowCheckId(state, value) {
      localStorage.setItem('nowCheckId', value)
      state.nowCheckId = value
    },
    setNowCheckScienceId(state, value) {
      localStorage.setItem('nowCheckSCienceId', value)
      state.nowCheckSCienceId = value
    },
    setUploadStatus(state, value) {
      state.isUploadStatus = value
    },
    setStoreUploadList(state, value) {
      state.storeUploadList = value
    }
  },
  actions: {
    goOut: ({ state }) => {
      removeToken()
      removeRefreshToken()
      sessionStorage.removeItem('Teacher-navList')
      state.navList = []
      // 跳转登录
      router.replace({
        name: 'login'
      })
    },
    async fetchLogin({ commit, state }, obj) {
      commit('setLoding', true)
      try {
        const res = await fetchloginApi(obj)
        commit('setLoding', false)
        // 如换账号登录，需要清除本地数据
        if (state.userInfo.id !== res.data.id) {
          localStorage.clear()
        }
        commit('setUserInfo', res.data)
        localStorage.setItem('user', JSON.stringify(res.data))
        commit('setToken', {
          token: res.data.token.access_token,
          refreshToken: res.data.token.refresh_token
        })
      } catch (error) {
        commit('setLoding', false)
        return await Promise.reject(error)
      }
    }
  },
  modules: {}
})
