<template>
  <teleport to="#loading" v-if="isShow">
    <div class="loading">
      <a-spin size="large" :tip="tip"> </a-spin>
    </div>
  </teleport>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'Loading',
  setup() {
    const store = useStore()
    const isShow = computed(() => {
      return store.state.isLoading
    })
    const tip = computed(() => {
      return store.state.loadingTips
    })
    return {
      isShow,
      tip,
    }
  },
})
</script>

<style>
.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999999;
}
</style>
