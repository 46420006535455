import request from '@/libs/request.js'
import userApi from './user.api.js'

export function fetchloginApi(data) {
  return request({
    url: userApi.login,
    method: 'post',
    data
  })
}

// 刷新token
export function fetchRefreshTokenApi(data) {
  return request({
    url: userApi.refreshToken,
    method: 'post',
    data
  })
}

// 获取导航栏
export function fetchSiteMenu(params) {
  return request({
    url: userApi.sitemenu,
    method: 'get',
    params
  })
}

// 获取上传时阿里云临时签名
export function getOssToken (params) {
  return request({
    url: userApi.getOssToken,
    method: 'get',
    params: Object.assign({
      client_id: 4
    }, params)
  })
}
