import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import AppBtn from './components/AppBtn/index.js'
import 'ant-design-vue/dist/antd.css'
import '@/assets/styles/reset.css'
import VueCropper from 'vue-cropper'; 
import 'vue-cropper/dist/index.css'
import '@/assets/font/iconfont.css'
createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .use(AppBtn)
  .use(VueCropper)
  .mount('#app')
