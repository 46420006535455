<template>
  <router-view />
  <loading></loading>
</template>
<script>
import loading from '@/components/Loading/index'

import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    loading,
  },
})
</script>

<style lang="scss">
.txt-r {
  text-align: right;
}
.imgBox {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  .img {
    width: 100%;
    height: 100%;
  }
}
//搜索和筛选导航
.screen-box {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  .screen {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .titleName {
      flex-shrink: 0;
      margin-right: 20px;
    }
  }
  .text {
    display: inline-block;
    font-size: 14px;
    color: #3a4f7e;
    cursor: pointer;
    flex-shrink: 0;
  }
  .item {
    margin: 0 15px;
    // background-color: red;
    width: 110px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border:1px solid #43B5DF;
    // background: #43B5DF;
    border-radius: 20px;
    color: #277896;
  }
  .active {
    // width: 110px;
    // height: 32px;
    background: #43B5DF;
    border-radius: 20px;
    color: #ffffff;
    // text-align: center;
    // line-height: 32px;
    padding: 0;
  }
}
.search-box{
    width: 420px;
    display: flex;
    align-items: center;
    .el-input__inner{
    width: 100%;
    height: 48px;
    background: #F6F8F9;
    font-size: 14px;
    color: #C2C4C8;  
    border-radius: 30px 0px 0px 30px;
  }
  }
  
  .searchBtn{
    width: 80px;
    height: 48px;
    background: #347BFF;
    border-radius: 0px 30px 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i{
      font-size: 20px;
      color: #fff;
    }
  }

   //导航
  .nav-menu{ 
    font-size: 12px;
    color: #5C78A8; 
    margin:15px 0;
    .active{
        color: #14408B;
    }
}
 .groupBtns{
    // width:auto;
    height:40px;
    display: flex;
    align-items: center;
    float:right;
    .btn{
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #206EE2;
        border-radius: 2px;       
        font-size: 14px;
        color: #206EE2;
        margin-left: 10px;
    }
    .btnActive{
        width: 129px;  
        background: #206EE2;
        color:#fff;
    }
}
</style>
