import Cookies from 'js-cookie'
const TOKEN_KEY = 'STUDENT_ADMIN_TOKEN'
const TOKEN_REFRESH_KEY = 'STUDENT_ADMIN_TOKEN_REFRESH'

// 存取token
export function getToken() {
  return Cookies.get(TOKEN_KEY)
}

export function setToken(token) {
  return Cookies.set(TOKEN_KEY, token)
}

export function removeToken() {
  return Cookies.remove(TOKEN_KEY)
}

// 存取refreshToken
export function getRefreshToken() {
  return Cookies.get(TOKEN_REFRESH_KEY)
}

export function setRefreshToken(token, expiresDay = 1) {
  return Cookies.set(TOKEN_REFRESH_KEY, token, {
    expires: expiresDay
  })
}

export function removeRefreshToken() {
  return Cookies.remove(TOKEN_REFRESH_KEY)
}
