import { createRouter, createWebHistory } from 'vue-router'
import { getRefreshToken } from '@/api/cookie.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import routes from './routes.js'
import store from '@/store'
import { message } from 'ant-design-vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export const subjectObj = [
  {
    subject_id: 1,
    name: '科学',
    routerName: 'sciencePage'
  },
  {
    subject_id: 2,
    name: '数学',
    routerName: 'mathematicsPage'
  }
]
const checkPower = (to, from, callBack) => {
  const _subject_id = to.meta.subject_id
  const _teacher_info = JSON.parse(localStorage.getItem('user')).teachs.filter(
    item => {
      if (_subject_id) {
        return item.subject_id === _subject_id
      } else {
        return item
      }
    }
  )
  callBack && callBack(_teacher_info)
  return _teacher_info.length
}
let _routerCheckName = ''
let _chekcPowerLen = true
let _isLoding = null

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (from.name && !['404', 'error', 'login'].includes(from.name)) {
    sessionStorage.setItem('oldRouter', from.name)
  }
  _isLoding = getRefreshToken()
  if (to.name === undefined) {
    next({
      name: '404'
    })
    return false
  }
  if (to.name === 'error') {
    next()
    return false
  }
  if (_isLoding) {
    _chekcPowerLen = checkPower(to, from, routerLen => {
      if (routerLen.length) {
        _routerCheckName = subjectObj.filter(
          item => item.subject_id === routerLen[0].subject_id
        )[0].routerName
      }
    })
    if (to.name === 'login') {
      next({
        path: '/'
      })
    } else {
      if (to.meta.authority) {
        if (_chekcPowerLen === 0) {
          next({
            name: 'error'
          })
        } else {
          if (to.name === 'login' && from.path === '/') {
            next({
              name: _routerCheckName
            })
          } else {
            next()
          }
        }
      } else {
        next()
      }
    }
  } else {
    if (!to.meta.authority) {
      next()
    } else {
      next({
        name: 'login',
        replace: true
      })
    }
  }
})
router.afterEach(() => {
  NProgress.done()
})

export default router
