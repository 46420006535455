// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    name: 'layout',
    redirect: 'home',
    component: _import('layout/layout'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: _import('home/home'),
        meta: {
          authority: true
        }
      },
      {
        path: 'sciencePage',
        name: 'sciencePage',
        meta: {
          subject_id: 1,
          authority: true
        },
        component: _import('sciencePage/sciencePage'),
        children:[{
          path:'scienceHome/:isShowView?/:course_id?',
          name: 'scienceHome',
          props:true,
          component: _import('scienceHome/scienceHome'),
          meta: {
            authority: true
          },
        }]
      },
      {
        path: 'mathematicsPage',
        name: 'mathematicsPage',
        meta: {
          subject_id: 2,
          authority: true
        },
        component: _import('mathematicsPage/mathematicsPage')
      },
      {
        path: 'arSourse',
        name: 'EncyclopediaBank',
        component: _import('arSourse/arSourse'),
        meta: {
          authority: true
        },
      },
      {
        path: 'resResourseInfo',
        name: 'BBSSourse',
        component: _import('resResourseInfo/resResourseInfo'),
        meta: {
          authority: true
        },
      },
      {
        path: 'resResourseDetail/:id',
        name: 'resResourseDetail',
        props:true,
        component: _import('resResourseDetail/resResourseDetail'),
        meta: {
          authority: true
        },
      },
      {
        path: 'resGroup',
        name: 'BBSGroup',
        component: _import('resGroup/resGroup'),
        meta: {
          authority: true
        },
      },
      {
        path: 'createGroup/:id?',
        name: 'createGroup',
        props:true,
        component: _import('createGroup/createGroup'),
        meta: {
          authority: true
        },
      },
      {
        path: 'othersCreate/:id?',
        name: 'othersCreate',
        props:true,
        component: _import('othersCreate/othersCreate'),
        meta: {
          authority: true
        },
      },
      {
        path: 'ownCreate/:id?',
        name: 'ownCreate',
        props:true,
        component: _import('ownCreate/ownCreate'),
        meta: {
          authority: true
        },
      },
      {
        path: 'resBlog',
        name: 'BBS',
        component: _import('resBlog/resBlog'),
        meta: {
          authority: true
        },
      },{
        path: 'createSourse/:id?',
        name: 'createSourse',
        props:true,
        component: _import('createSourse/createSourse'),
        meta: {
          authority: true
        },
      },
      {
        path: 'teachResearch',
        name: 'teachResearch',
        redirect: '/teachResearch/imgSourse',
        component: _import('teachResearch/teachResearch'),
        children: [{
          path: 'imgSourse',
          name: 'imgSourse',
          component: _import('imgSourse/imgSourse'),
          meta: {
            authority: true
          },
        },{
          path: 'radioSourse',
          name: 'radioSourse',
          component: _import('radioSourse/radioSourse'),
          meta: {
            authority: true
          },
        },
        {
          path: 'wordSourse',
          name: 'wordSourse',
          component: _import('wordSourse/wordSourse'),
          meta: {
            authority: true
          },
        },{
          path: 'myResResourse',
          name: 'bbsSourse',
          props:true,
          component: _import('myResResourse/myResResourse'),
          meta: {
            authority: true
          },
        }, {
          path: 'encyclopediaBank',
          name: 'modelSourse',
          component: _import('encyclopediaBank/encyclopediaBank'),
          meta: {
            authority: true
          },
        }]
      },
      {
        path: 'personInfo',
        name: 'personInfo',
        redirect: '/personInfo/myDynamic',
        component: _import('personInfo/personInfo'),
        children:[{
          path: 'myDynamic',
          name: 'myDynamic',
          component: _import('myDynamic/myDynamic'),
          meta: {
            authority: true
          },
        },{
          path: 'myFollow',
          name: 'myFollow',
          component: _import('myFollow/myFollow'),
          meta: {
            authority: true
          },
        },{
          path: 'followMe',
          name: 'followMe',
          component: _import('followMe/followMe'),
          meta: {
            authority: true
          },
        },{
          path: 'myGroup',
          name: 'myGroup',
          component: _import('myGroup/myGroup'),
          meta: {
            authority: true
          },
        },{
          path: 'setPersonInfo',
          name: 'setPersonInfo',
          component: _import('setPersonInfo/setPersonInfo'),
          meta: {
            authority: true
          },
        }]
      },
      {
        path: 'externalDisplay',
        name: 'externalDisplay',
        redirect: '/externalDisplay/taDynamic',
        component: _import('externalDisplay/externalDisplay'),
        children:[{
          path: 'taDynamic',
          name: 'taDynamic',
          component: _import('taDynamic/taDynamic'),
          meta: {
            authority: true
          },
        },{
          path: 'taResourse',
          name: 'taResourse',
          component: _import('taResourse/taResourse'),
          meta: {
            authority: true
          },
        },{
          path: 'taGroup',
          name: 'taGroup',
          component: _import('taGroup/taGroup'),
          meta: {
            authority: true
          },
        },{
          path: 'followTa',
          name: 'followTa',
          component: _import('followTa/followTa'),
          meta: {
            authority: true
          },
        },{
          path: 'taFollow',
          name: 'taFollow',
          component: _import('taFollow/taFollow'),
          meta: {
            authority: true
          },
        }]
      },
      {
        path: 'createARsourse/:id?',
        name: 'createARsourse',
        props:true,
        meta: {
          authority: true
        },
        component: _import('createARsourse/createARsourse')
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('login/login')
  }
]

/**
 * 错误页面
 */
 const errorPage = [
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: _import('error/404')
  }
]

const notFoundPage = [
  {
    path: '/error',
    name: 'error',
    component: _import('error/error')
  }
]

export default [...frameIn, ...frameOut, ...errorPage, ...notFoundPage]
