import axios from 'axios'
import userApi from '@/api/user/user.api.js'
import { fetchRefreshTokenApi } from '@/api/user/index.js'
import md5 from 'js-md5'
import { getToken, getRefreshToken } from '@/api/cookie.js'
import store from '@/store/index'
import { message } from 'ant-design-vue'
import { screenObj } from '@/hooks/index'
message.config({
  maxCount: 1
})
function getRandom(start, end) {
  const differ = end - start
  const random = Math.random()
  const _x = (start + differ * random).toFixed(0)
  return Number(_x)
}

function getSortFormData(o) {
  const n = []
  for (const k in o) n.push(k)
  n.sort()
  let str = ''
  for (let i = 0; i < n.length; i++) {
    let v = o[n[i]]
    if (v) {
      if ({}.toString.call(v) === '[object Object]') {
        v = getSortFormData(v)
      } else if ({}.toString.call(v) === '[object Array]') {
        v = JSON.stringify(v).replace(/:/g, '=')
      }
    }
    str += '&' + n[i] + '=' + v
  }
  return str.slice(1)
}

const service = axios.create({
  baseURL: process.env.VUE_APP_HTTP
})

// 排除登录和刷新token携带token
function checkUrl(url) {
  if (url) {
    if (
      url.indexOf(userApi.refreshToken) >= 0 ||
      url.indexOf(userApi.login) >= 0
    ) {
      return true
    }
  }
  return false
}

service.interceptors.request.use(
  config => {
    if (config.loading !== false) {
      // 请求数据的loading加载
      store.commit('setLoding', true)
    }
    const client = 'arseek.web'
    const timeStamp = new Date().getTime()
    const key = '379ef4bd50c30e261ccfb18dfc626d9f'
    const nonce = getRandom(100000, 999999)
    const methods = config.method
    const token = getToken() === undefined ? '' : 'Bearer ' + getToken()
    let _token = token ? token.substr(7) : ''
    let sign = null
    config.headers.client = client
    config.headers.timestamp = timeStamp
    config.headers.nonce = nonce
    config.headers.Authorization = token
    if (checkUrl(config.url)) {
      _token = ''
      config.headers.Authorization = ''
    }

    if (config.params && Object.keys(config.params).length !== 0) {
      config.params = screenObj(config.params)
    }
    if (config.data && Object.keys(config.data).length !== 0) {
      config.data = screenObj(config.data)
    }
    switch (methods) {
      case 'get':
        sign = md5(
          getSortFormData(config.params).toLocaleLowerCase() +
            _token +
            key +
            timeStamp +
            nonce
        )
        break
      default:
        sign = md5(
          JSON.stringify(config.data).toLocaleLowerCase() +
            _token +
            key +
            timeStamp +
            nonce
        )
        break
    }
    config.headers.sign = sign
    return config
  },
  error => {
    // 关闭动画
    store.commit('setLoding', false)
    // 提示错误
    return Promise.reject(error)
  }
)

let isRefreshing = false
let subscribers = []

// 刷新token
export async function getNewToken(callback) {
  const hasRefreshToken = getRefreshToken()
  if (hasRefreshToken) {
    await fetchRefreshTokenApi({
      refresh_token: hasRefreshToken
    })
      .then(res => {
        const _token = res.data.access_token
        const _refreshToken = res.data.refresh_token
        store.commit('setToken', {
          token: _token,
          refreshToken: _refreshToken
        })
        callback && callback(res)
      })
      .catch(() => {
        // 退出登录
      })
      .finally(() => {
        // 就看见
        isRefreshing = false
      })
  }
}

function onAccessTokenFetched(res) {
  subscribers.forEach(callBack => {
    callBack(res)
  })
  subscribers = []
}
function addSubscriber(callBack) {
  subscribers.push(callBack)
}

service.interceptors.response.use(
  response => {
    const res = response.data
    store.commit('setLoding', false)
    if (!res.success && response?.config?.url?.indexOf('token/refresh') >= 0) {
      message.error(res.msg || 'Error')
      store.dispatch('goOut')
      return Promise.reject(res)
    }
    if (!res.success && !response.config.error) {
      // 统一错误提示
      message.error(res.msg || 'Error')
      return Promise.reject(res)
    }
    if (res.success) {
      return Promise.resolve(res)
    }
    if (!res.success) {
      return Promise.reject(res)
    }
    return Promise.reject(res)
  },
  error => {
    store.commit('setLoding', false)

    if (error.message.includes('Network Error')) {
      message.error('网络出错啦，请检查网络连接')
    }
    const code = error.response.status

    switch (code) {
      case 401: {
        if (error.response.data.code === -4009) {
          store.dispatch('goOut')
          // 下线通知
          // warninConfirm(error.response.data.sub_msg)
        } else {
          if (!isRefreshing) {
            isRefreshing = true
            getNewToken(res => {
              onAccessTokenFetched(res)
            })
          }
          const retryOriginalRequest = new Promise((resolve, reject) => {
            addSubscriber(newToken => {
              const response = error.response.config
              response.headers.Authorization = `Bearer ${newToken.data.access_token}`
              let data = response.data
                ? JSON.parse(response.data)
                : response.data
              const id = response.id
              if (response.method === 'get') {
                data = response.params
                service({
                  url: response.url,
                  method: response.method,
                  params: data
                })
                  .then(res => {
                    resolve(res)
                  })
                  .catch(() => {
                    reject(new Error())
                  })
              } else {
                service({
                  url: response.url,
                  method: response.method,
                  data: data,
                  id
                })
                  .then(res => {
                    resolve(res)
                  })
                  .catch(() => {
                    reject(new Error())
                  })
              }
            })
          })
          return retryOriginalRequest
        }
        break
      }
      case 500: {
        message.error('错误代码：500, 请联系管理员')
        break
      }
      default:
        message.error(
          `错误代码：${code},错误详情：${
            error.response.data.msg === undefined ? '' : error.response.data.msg
          }`
        )
        break
    }

    return Promise.reject(error)
  }
)

export default service
