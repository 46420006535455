/**
 * 筛选参数
 * @param {Object} obj
 */
 export const screenObj = (obj) => {
  const _obj = {}
  for (const key in obj) {
    if (typeof obj[key] === 'boolean' || obj[key] !== undefined && obj[key] !== null) _obj[key] = obj[key]  
  }
  return _obj
}
